new Vue({
  el: '#dark',
  data: function() {
    return {
      isDark: false,
    }
  },
  methods: {
    toggleDark() {
      if (this.isDark === false) {
        this.isDark = true;
        localStorage.setItem('dark', true);
      }else {
        this.isDark = false;
        localStorage.removeItem('dark');
      }
      window.location.reload();
    }
  },
  created() {
    if (localStorage.getItem('dark')) {
      this.isDark = true;
    }else{
      this.isDark = false;
    }
  }
});